import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/css/style.scss";
import Chat from "./pages/Chat";
import Login from "./pages/Login";

const router = createBrowserRouter([
  { path: "/", element: <Login /> },
  { path: "/chat", element: <Chat /> },
]);

const theme = createTheme({
  palette: {
    primary: { main: "#014998", contrastText: "#fff" },
    secondary: { main: "#ee7325", contrastText: "#000" },
  },
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <RouterProvider router={router} />
      </Box>
    </ThemeProvider>
  </StrictMode>
);
