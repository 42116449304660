import Avatar from "@mui/material/Avatar";
import React from "react";
// import Logo from "../assets/img/Logo.png";
import LogoLight from "../assets/img/Logo_light.png";

export default function Header() {
  return (
    <div
      style={{
        height: 60,
        width: "100%",
        backgroundColor: "#d7342a",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "flex-end",
          }}
        >
          <Avatar
            src={LogoLight}
            alt="DatapizzaAssistant"
            sx={{ width: "auto", height: "100%" }}
            style={{ borderRadius: 0 }}
          />
        </div>
        {/* <div
          style={{
            height: "100%",
            alignItems: "flex-end",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="h5"
            style={{ marginBottom: 0, color: "white", fontWeight: "bold" }}
          >
            Assistant
          </Typography>
        </div> */}
      </div>
    </div>
  );
}
