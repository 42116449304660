import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { degrees, PDFDocument, rgb } from "pdf-lib";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import LogoLightWhite from "../assets/img/Logo_light2.png";
// import Logo from "../assets/img/Logo.png";
import AssistantImage from "../assets/img/assistant_img.png";
import UserImage from "../assets/img/user_img.png";
import Header from "../components/Header";

export default function Chat() {
  const [messages, setMessages] = useState([
    {
      id: 0,
      role: "bot",
      text: "Ciao! Chiedimi qualcosa sui documenti.",
      passages: null,
    },
  ]);

  const [waiting, setWaiting] = useState(false);
  const [waitingText, setWaitingText] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const messageText = useRef("");
  const [chatTurn, setChatTurn] = useState(0);
  const [files, setFiles] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const messagesEndRef = React.createRef();
  const appEndPointUrl = process.env.REACT_APP_API_URL;
  const textFieldRef = useRef(null); // Ref for the TextField element

  const { state } = useLocation();
  const navigate = useNavigate();
  const username = state?.username;
  const password = state?.password;

  useEffect(() => {
    if (username && password) {
      setAuthenticated(true);
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (files == null && authenticated) getPdfFiles();
  }, [files, authenticated]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (waiting) {
      let i = 1;
      const interval = setInterval(() => {
        if (i > 3) {
          setWaitingText({ text: "." });
          i = 1;
        } else {
          setWaitingText({ text: ".".repeat(i) });
          i++;
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [waiting]);

  useEffect(() => {
    // Update the iframe src when the page number changes
    if (pageNumber && pdfFile) {
      const src = `${pdfFile}#page=${pageNumber.toString()}`;
      const new_pdf_viewer_html = `<iframe 
      id="pdf-viewer"
      title="pdf-reader"
      type="application/pdf"
      width="100%"
      height="100%"
      src="${src}"
      class="pdf-embed" type="application/pdf" width="100%" height="100%"></iframe>
      `;
      const pdfViewer = document.getElementById("pdf-viewer");
      pdfViewer.remove();
      const pdfViewerContainer = document.getElementById(
        "pdf-viewer-container"
      );
      pdfViewerContainer.insertAdjacentHTML("beforeend", new_pdf_viewer_html);
    }
  }, [pageNumber, pdfFile]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sortObjectByKeys = useCallback((obj) => {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = obj[key];
        return sortedObj;
      }, {});
  }, []);

  const processBase64ToBlob = useCallback((base64String) => {
    if (
      base64String == null ||
      base64String === "" ||
      base64String === undefined
    ) {
      return null;
    }
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });

    const uri = URL.createObjectURL(blob);

    return uri;
  }, []);

  const getPdfFiles = useCallback(async () => {
    try {
      const credentials = btoa(username + ":" + password);

      let response = await fetch(appEndPointUrl + "/pdfs", {
        headers: {
          Authorization: "Basic " + credentials,
          // "Content-Type": "application/json",
          // Accept: "application/json",
        },
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      console.log(response);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let decoderBuffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decodedString = decoder.decode(value);
        decoderBuffer += decodedString;
      }
      const decodedJson = JSON.parse(decoderBuffer);
      const sortedJson = sortObjectByKeys(decodedJson);
      for (let i = 0; i < Object.keys(sortedJson).length; i++) {
        const filename = Object.keys(sortedJson)[i];
        sortedJson[filename]["blob"] = processBase64ToBlob(
          sortedJson[filename]["blob"]
        );
      }

      setFiles(sortedJson);
      setPageNumber(1);
      setLoading(false);
    } catch (err) {
      console.log("Failed to list files: " + err);
    }
  }, [
    appEndPointUrl,
    sortObjectByKeys,
    processBase64ToBlob,
    username,
    password,
  ]);

  const drawBoundingBox = useCallback(async (blobUrl, boundingBox) => {
    const pageNumber = boundingBox.pageNumber - 1;
    const response = await fetch(blobUrl);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const page = pdfDoc.getPages()[pageNumber];
    const { width: pageWidth, height: pageHeight } = page.getSize();

    const inchesToPoints = (inches) => inches * 72;

    // Extract coordinates and convert to points
    const [x1, y1, x2, y2, x3, y3, x4, y4] =
      boundingBox.polygon.map(inchesToPoints);

    // Calculate rotation angle
    const angle = Math.atan2(y2 - y1, x2 - x1);
    const rotationDegrees = angle * (180 / Math.PI);

    // Calculate width and height of the bounding box
    const width = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    const height = Math.sqrt(Math.pow(x4 - x1, 2) + Math.pow(y4 - y1, 2));

    // Draw the bounding box
    page.drawRectangle({
      x: x1,
      y: pageHeight - y1 - height, // Adjust y-coordinate to start from top and go down
      width: width,
      height: height,
      borderColor: rgb(1, 0, 0), // Red border
      borderWidth: 1.5,
      borderDashArray: [4, 0.5],
      rotate: degrees(rotationDegrees),
      borderOpacity: 0.8,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const newBlobUrl = URL.createObjectURL(blob);
    // Return new blob URL and the bounding box's top-left coordinates
    return {
      newBlobUrl: newBlobUrl,
      x: x1,
      y: y1,
    };
  }, []);

  const handleClickChangeFile = useCallback(
    async (key, pageNumberParam, passage) => {
      setPdfLoading(true);
      let fileBlob = files[key]["blob"];
      if (passage && passage.bounding_regions) {
        const scrollToX = [];
        const scrollToY = [];
        for (let i = 0; i < passage.bounding_regions.length; i++) {
          const result = await drawBoundingBox(
            fileBlob,
            passage.bounding_regions[i][0]
          );
          fileBlob = result.newBlobUrl;
          scrollToX.push(result.x);
          scrollToY.push(result.y);
        }
        setPdfFile(fileBlob + "#page=" + pageNumberParam.toString());

        // Add a small delay to ensure the PDF is loaded before scrolling
        setTimeout(() => {
          const pdfViewer = document.getElementById("pdf-viewer");
          if (pdfViewer) {
            pdfViewer.onload = () => {
              pdfViewer.contentWindow.scrollTo(scrollToX[0], scrollToY[0]);
            };
          }
        }, 1000);
      } else {
        setPdfFile(fileBlob + "#page=" + pageNumberParam.toString());
      }
      setPdfLoading(false);
    },
    [drawBoundingBox, files]
  );

  const MessageBot = useCallback(
    (message) => {
      console.log(message);
      return (
        <Container
          elevation={3}
          style={{
            display: "flex",
            padding: "10px",
            marginBottom: "10px",
            backgroundColor: "transparent",
            boxShadow: "none",
            justifyContent: "left",
            width: "100%",
            paddingRight: 5,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: 40,
              height: 40,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderRadius: 40 / 2,
              marginTop: 5,
            }}
          >
            <Avatar
              src={AssistantImage}
              alt="DatapizzaAssistant"
              sx={{ width: "auto", height: "100%", borderRadius: 2 }}
            />
          </div>
          <div
            style={{
              height: "100%",
              marginLeft: "10px",
              overflow: "hidden",
              flex: 1,
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: "white",
                height: "100%",
                marginTop: 8,
              }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {message.text}
              </ReactMarkdown>
            </Typography>
            <Box
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              {message.passages && (
                <Box
                  style={{ width: "100%", marginTop: 15, overflow: "hidden" }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    style={{
                      color: "white",
                      height: "100%",
                      marginTop: 8,
                      textOverflow: "ellipsis",
                    }}
                  >
                    Sources:
                  </Typography>
                  {Object.keys(message.passages).map((key) => (
                    <Container
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        padding: 0,
                        overflow: "hidden",
                        borderBottom: "0.5px solid white",
                      }}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          padding: 0,
                          paddingRight: 2,
                          maxWidth: "80%",
                        }}
                      >
                        <Typography
                          fontWeight={"500"}
                          fontStyle={"italic"}
                          style={{
                            color: "white",
                            height: "100%",
                            marginTop: 3,
                            marginRight: 10,
                            textOverflow: "ellipsis",
                            fontSize: 13,
                            overflow: "hidden",
                          }}
                        >
                          •{key}:
                        </Typography>
                      </div>
                      <div
                        style={{
                          padding: 0,
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        {message.passages[key].length ? (
                          message.passages[key]?.map((p) => (
                            <Link
                              onClick={async () =>
                                await handleClickChangeFile(key, p.page, p)
                              }
                              style={{ marginRight: 10 }}
                              color={"#ec8c84"}
                              fontSize={15}
                            >
                              {p.page}
                            </Link>
                          ))
                        ) : (
                          <Typography
                            fontWeight={"500"}
                            fontStyle={"italic"}
                            style={{
                              color: "white",
                              height: "100%",
                              marginTop: 3,
                              marginRight: 10,
                              textOverflow: "ellipsis",
                              fontSize: 12,
                            }}
                          >
                            N/A
                          </Typography>
                        )}
                      </div>
                    </Container>
                  ))}
                </Box>
              )}
            </Box>
          </div>
        </Container>
      );
    },
    [handleClickChangeFile]
  );

  const MessageUser = useCallback(
    (message) => (
      <Container
        elevation={3}
        style={{
          display: "flex",
          padding: "10px",
          marginBottom: "10px",
          backgroundColor: "transparent",
          boxShadow: "none",
          justifyContent: "left",
          width: "100%",
          paddingRight: 5,
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 40 / 2,
            marginTop: 5,
          }}
        >
          <Avatar src={UserImage} alt="User" sx={{ width: 40, height: 40 }} />
        </div>

        {/* Testo del messaggio a destra */}
        <div
          style={{
            height: "100%",
            marginLeft: "10px",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: "white",
              height: "100%",
              marginTop: 8,
            }}
          >
            {message.text}
          </Typography>
        </div>
      </Container>
    ),
    []
  );

  const DefaultViewer = useCallback(() => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          src={LogoLightWhite}
          alt="DatapizzaAssistant"
          sx={{ width: "50%", height: "auto", borderRadius: 30 }}
          style={{ borderRadius: 30 }}
        />
      </div>
    );
  }, []);

  const processChunkResponse = useCallback((chunkString, botMessageId) => {
    let chunkStringList = chunkString.split("\n");
    for (let i = 0; i < chunkStringList.length; i++) {
      if (chunkStringList[i].length > 0) {
        let chunk = JSON.parse(chunkStringList[i]);
        if (chunk["passages"]) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === botMessageId
                ? { ...msg, passages: chunk["passages"] }
                : msg
            )
          );
        }
        if (chunk["response"]) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === botMessageId
                ? { ...msg, text: msg.text + chunk["response"] }
                : msg
            )
          );
        }
      }
    }
  }, []);

  const sendMessage = useCallback(async () => {
    if (messageText.current === "") {
      return;
    }
    setChatTurn((chatTurn) => chatTurn + 1);
    let messageCurrent = {
      id: chatTurn + 1,
      role: "User",
      text: messageText.current,
      passages: [],
    };

    setMessages((messages) => [...messages, messageCurrent]);
    messageText.current = "";
    if (textFieldRef.current) {
      textFieldRef.current.value = ""; // Set input value to an empty string
    }
    setWaiting(true);

    let params = new URLSearchParams({
      prompt: messageCurrent.text,
    });

    const credentials = btoa(username + ":" + password);

    let response = await fetch(appEndPointUrl + "/chat?" + params, {
      headers: {
        Authorization: "Basic " + credentials,
        // "Content-Type": "application/json",
        // Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    setWaiting(false);

    const botMessageId = chatTurn + 2;
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: botMessageId, role: "bot", text: "", passages: null },
    ]);
    let temp_accumulator = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      try {
        let chunkString = decoder.decode(value);
        if (chunkString.indexOf("\n") === -1) {
          temp_accumulator += chunkString;
          continue;
        }
        let chunkStringFirst = chunkString.split("\n")[0];
        processChunkResponse(temp_accumulator + chunkStringFirst, botMessageId);
        temp_accumulator = chunkString.split("\n").slice(1).join("\n");
      } catch (e) {
        console.log(e);
      }
    }
    if (temp_accumulator.length > 0) {
      processChunkResponse(temp_accumulator, botMessageId);
    }
    setChatTurn((chatTurn) => chatTurn + 2);
  }, [appEndPointUrl, chatTurn, messageText, processChunkResponse]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        sendMessage();
      }
    },
    [sendMessage]
  );

  const textFieldStyle = {
    width: "100%",
    borderRadius: 10,
    overflowY: "auto",
    border: 0,
    backgroundColor: "white",
  };

  const handleChangeText = useCallback(
    (e) => (messageText.current = e.target.value),
    []
  );

  return (
    <Container
      style={{
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        flex: 1,
        margin: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#3c3c3c",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Opacity for the background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it's on top of other elements
          }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      )}
      {/* Header */}
      <Header />
      <Divider
        orientation="horizontal"
        style={{ width: "100%", height: 2, backgroundColor: "lightgrey" }}
      />

      {/* Chat Feed */}
      <Container
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          maxWidth: "100%",
          flexDirection: "row",
          padding: 0,
          margin: 0,
          overflow: "hidden",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Container
          id="pdf-viewer-container"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Container
            style={{
              width: "100%",
              padding: 0,
              paddingTop: 5,
              paddingBottom: 10,
              alignItems: "flex-end",
              display: "flex",
              border: 0,
            }}
          >
            <Box
              sx={{
                padding: 0,
                overflowX: "auto",
                whiteSpace: "nowrap",
                "&::-webkit-scrollbar": {
                  height: 10,
                  padding: 1,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: 5,
                  marginTop: "auto",
                  marginBottom: "auto",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                  marginTop: "auto",
                  marginBottom: "auto",
                },
              }}
            >
              {files &&
                Object.keys(files).map((f, idx) => (
                  <Button
                    key={idx}
                    style={{
                      height: "100%",
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 0,
                    }}
                    sx={{
                      flex: "0 0 auto",
                      width: "calc(25% - 7px)", // 25% width minus margin
                      maxWidth: "25%",
                      minWidth: "150px", // Ensure buttons don't get too small
                      padding: "6px",
                      marginRight: "8px",
                      whiteSpace: "nowrap",
                      borderRight: "0.5px solid lightgrey",
                    }}
                    onClick={async () => {
                      await handleClickChangeFile(f, 1, null);
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {files[f]?.metadata?.filename}
                    </Typography>
                  </Button>
                ))}
            </Box>
          </Container>
          {pdfFile ? (
            <iframe
              id="pdf-viewer"
              title="pdf-reader"
              src={pdfFile}
              type="application/pdf"
              width="100%"
              height="100%"
            ></iframe>
          ) : (
            <DefaultViewer />
          )}

          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: pdfLoading ? "flex" : "none",
              border: 1,
              backgroundColor: "rgba(0, 0, 0, 0.8)", // Opacity for the background
            }}
          >
            <CircularProgress
              style={{
                color: "white",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
        </Container>

        <Divider
          orientation="vertical"
          style={{
            width: 10,
            height: "100%",
            backgroundColor: "rgb(44, 38, 38)",
          }}
        />

        <Container
          style={{
            flex: 1,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
            padding: 0,
            margin: 0,
          }}
        >
          <List
            style={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {messages.map((message) => (
              <ListItem key={message.id} style={{ color: "white" }}>
                {message.role === "bot"
                  ? MessageBot(message)
                  : MessageUser(message)}
              </ListItem>
            ))}
            {waiting ? (
              <ListItem key={"waiting_id"} style={{ color: "white" }}>
                {MessageBot(waitingText)}
              </ListItem>
            ) : null}
            <div ref={messagesEndRef} />
          </List>
        </Container>
      </Container>
      {/* Chat Input Footer */}

      <Divider
        orientation="horizontal"
        style={{ width: "100%", height: 2, backgroundColor: "grey" }}
      />
      <Box
        display="flex"
        alignItems="center"
        p={2}
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#3c3c3c",
          paddingTop: 10,
          maxWidth: "100%",
        }}
      >
        <TextField
          label="Scrivi un messaggio..."
          multiline
          fullWidth
          rows={1} // Minimum rows
          maxRows={3} // Maximum rows before scroll
          variant="outlined"
          inputRef={textFieldRef}
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
          style={textFieldStyle}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "8px", backgroundColor: "#d7342a" }}
          onClick={sendMessage}
        >
          Invio
        </Button>
      </Box>
    </Container>
  );
}
